@import './utils';

@mixin mediaBreakpointAfter($size) {
  $breakpoint: getBreakpointBySize($size);
  $maxWidth: map-get($breakpoint, maxWidth);

  @if ($maxWidth != null) {
    @media (min-width: $maxWidth + 1) {
      @content;
    }
  }
}

@mixin mediaBreakpointBefore($size) {
  $breakpoint: getBreakpointBySize($size);
  $minWidth: map-get($breakpoint, minWidth);

  @if ($minWidth != null) {
    @media (max-width: $minWidth - 1) {
      @content;
    }
  }
}

@mixin mediaBreakpointBetween($sizeA, $sizeB) {
  $breakpointA: getBreakpointBySize($sizeA);
  $breakpointB: getBreakpointBySize($sizeB);

  $minWidth: map-get($breakpointA, minWidth);
  $maxWidth: map-get($breakpointB, maxWidth);

  @if ($minWidth != null and $maxWidth != null) {
    @media (min-width: $minWidth) and (max-width: $maxWidth) {
      @content;
    }
  }
}

@mixin mediaBreakpoint($size) {
  $breakpoint: getBreakpointBySize($size);
  $minWidth: map-get($breakpoint, minWidth);
  $maxWidth: map-get($breakpoint, maxWidth);

  @if ($minWidth != null and $maxWidth != null) {
    @media (min-width: $minWidth) and (max-width: $maxWidth) {
      @content;
    }
  } @else if ($maxWidth != null) {
    @media (max-width: $maxWidth) {
      @content;
    }
  } @else if ($minWidth != null) {
    @media (min-width: $minWidth) {
      @content;
    }
  }
}
