.vimbox-sticky-holder,
.lecta-sticky-holder {
  // this file won't be processed by postcss because of import in sticky-holder directive
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.92);
  z-index: 9;

  &.-limited-size {
    @include scrollbar;
    max-height: 30vh;
    overflow-y: scroll;
  }
}
