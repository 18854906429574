$scrollbarWidth: 6px;

@mixin scrollbar {
  overflow-y: scroll !important;
  transform: translateZ(0);

  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar {
    width: $scrollbarWidth;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    transition: background-color 2s ease-out;
    border-radius: 6px;
  }

  // styles for Gecko-based browsers (Firefox)
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-width: thin;
}

@mixin outsideScrollbar($outsideBackgroundColor) {
  $nativeScrollbarWidth: 14px;

  @include scrollbar();

  margin-right: -$nativeScrollbarWidth;

  &::-webkit-scrollbar {
    z-index: 5;
    width: $nativeScrollbarWidth;
  }

  &::-webkit-scrollbar-track {
    background-color: $outsideBackgroundColor;
  }

  &::-webkit-scrollbar-thumb {
    z-index: 5;
    border-radius: 3px;
    box-shadow: inset 6px 0 0 0 $outsideBackgroundColor, inset -6px 0 0 0 $outsideBackgroundColor;
  }
}
