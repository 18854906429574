@font-face {
  font-family: 'GolosLecta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('golos-regular.woff2') format('woff2'), url('golos-regular.woff') format('woff');
}

@font-face {
  font-family: 'GolosLecta';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('golos-medium.woff2') format('woff2'), url('golos-medium.woff') format('woff');
}

@font-face {
  font-family: 'GolosLecta';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('golos-bold.woff2') format('woff2'), url('golos-bold.woff') format('woff');
}
