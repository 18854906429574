@import '~normalize.css/normalize.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import '~quill/dist/quill.snow.css';
@import './styles/accessibility';
@import './styles/ng-select';
@import './styles/math-input';
@import './vars';
@import './assets/golos-font/golos.css';
@import './styles/prosvet-theme';
@import './styles/theme/main';
@import './styles/other';
@import './core/exercise/blocks/common/sticky-holder/styles/sticky-holder-global';

// --> Step editor styles for CMS
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/solarized.css';
@import '~codemirror/addon/fold/foldgutter.css';
@import '~codemirror/addon/hint/show-hint.css';

.CodeMirror {
  height: 100%;
  min-height: 100%;
}
.cm-s-solarized.CodeMirror {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
}
// <-- Step editor styles for CMS

:root {
  @include initTheme($prosvetTheme);
}

html,
body {
  height: 100%;
}

body {
  @include lectaFontSize(text, m);
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: getColor(text, primary, base);
  // Disables pull-to-refresh but allows overscroll glow effects.
  overscroll-behavior-y: contain;
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  color: getColor(text, primary, base);
  text-decoration: none;
}

[hidden] {
  display: none !important;
}

app-tooltip{
  display: none;
}

.cdk-overlay-container{
  app-tooltip{
    display: block;
  }
}
