app-teacher-how-to-work-note-books-snackbar{
  position: fixed;
  display: flex;
  z-index: 1000;
  bottom: 92px;
  right: 56px;
}

app-teacher-how-to-work-note-books-page {
    lecta-modal-dialog {
      .root {
        .topbar {
          padding-bottom: 4px !important;
        }

        .autoscroll-area {
          border-top: none !important;

          .content {
            padding-top: 0 !important;
          }
        }

        &.-mobile {
          background: #fbfcff !important;

          lecta-button-link {
            &.close-button {
              width: 24px;
              height: 24px;
              display: block !important;
              min-height: auto !important;

              lecta-button {
                button {
                  width: 24px;
                  height: 24px;
                  display: block !important;
                  border-radius: 100%;
                  background: #f0f4ff !important;

                  .icon-alone {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;

                    lecta-icon {
                      height: 16px !important;
                      width: 16px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  @media (max-width: $mobileBreakpoint) {
    .topbar{
      padding-top: 55px !important;
      padding-right: 0 !important;
    }

  }
}
lecta-modal-layout{
  lecta-modal-dialog.not-fullscreen-mode {
    app-exercise-content-renderer{
      .sticky-holder {
        top: 0 !important;
      }
    }
  }
}
