@import './media/main';

@mixin typography($name, $size, $device) {
  font-family: getCssVar(font, family), serif;
  font-size: getCssVar(font, $name, $size, $device, fontSize);
  line-height: getCssVar(font, $name, $size, $device, lineHeight);
  font-weight: getCssVar(font, $name, $size, $device, fontWeight);
  letter-spacing: getCssVar(font, $name, $size, $device, letterSpacing);
  font-feature-settings: 'pnum' on, 'lnum' on;
}

@mixin lectaFontSize($type, $size) {
  @include typography($type, $size, desktop);

  @include mediaBreakpointBefore(m) {
    @include typography($type, $size, mobile);
  }
}
