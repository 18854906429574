@import './vars';
@import './utils';

/* prettier-ignore */
$prosvetFontThemes: (
  family: #{'GolosLecta',
  Arial,
  sans-serif},
  heading: (
    getFontSizesValuesByDevices(
      (
        desktop: (
          xxl: 60px $fontWeightNormal 64px 0,

          xlPromo: 64px $fontWeightMedium 72px 0,
          lPromo: 48px $fontWeightMedium 56px 0,
          descPromo: 44px $fontWeightNormal 54px 0,
          xl: 40px $fontWeightMedium 48px 0,
          l: 32px $fontWeightMedium 40px 0,
          m: 24px $fontWeightMedium 32px 0,
          s: 20px $fontWeightMedium 24px 0,
          xs: 18px $fontWeightMedium 24px 0,
          xxs: 16px $fontWeightMedium 24px 0
        ),
        mobile: (
          xxxxlPromo: 64px $fontWeightMedium 72px 0,
          xxxlPromo: 48px $fontWeightNormal 48px 0,
          xxlPromo: 40px $fontWeightMedium 48px 0,
          xxlPromoN: 40px $fontWeightNormal 48px 0,
          bannerTitlePromo: 32px $fontWeightMedium 40px 0,
          descPromo: 24px $fontWeightNormal 32px 0,
          xxl: 32px $fontWeightMedium 36px -0.3px,

          xlPromo: 32px $fontWeightMedium 36px 0,
          lPromo: 32px $fontWeightMedium 36px 0,
          xl: 28px $fontWeightMedium 32px 0,
          l: 24px $fontWeightMedium 30px 0,
          m: 20px $fontWeightMedium 26px 0,
          s: 20px $fontWeightMedium 26px 0,
          xs: 16px $fontWeightMedium 24px 0,
          xxs: 14px $fontWeightMedium 18px 0
        ),
      )
    )
  ),
  text: (
    getFontSizesValuesByDevices(
      (
        desktop: (
          landingFacts: 24px $fontWeightNormal 32px 0,

          lContent: 18px $fontWeightNormal 34px 0,
          mBold: 16px $fontWeightSemibold 24px 0,

          xl: 24px $fontWeightNormal 32px 0,
          l: 18px $fontWeightNormal 24px 0,
          m: 16px $fontWeightNormal 24px 0,
        ),
        mobile: (
          landingFacts: 18px $fontWeightNormal 26px 0,

          lContent: 16px $fontWeightNormal 30px 0,
          mBold: 16px $fontWeightSemibold 24px 0,

          xl: 20px $fontWeightNormal 26px 0,
          l: 18px $fontWeightNormal 24px 0,
          m: 16px $fontWeightNormal 24px 0,
        ),
      )
    )
  ),
  caption: (
    getFontSizesValuesByDevices(
      (
        desktop: (
          m: 16px $fontWeightNormal 22px 0,
          s: 14px $fontWeightNormal 18px 0,
          xs: 12px $fontWeightNormal 16px 0,
          xsMedium: 12px $fontWeightMedium 14px 0
        ),
        mobile: (
          m: 16px $fontWeightNormal 22px 0,
          s: 14px $fontWeightNormal 18px 0,
          xs: 12px $fontWeightNormal 16px 0,
          xsMedium: 12px $fontWeightMedium 14px 0
        ),
      )
    )
  ),
);
