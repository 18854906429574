@import './utils';

@mixin initTheme($theme) {
  $cssVarsList: createCssVarsList($theme, $cssVarPrefix);

  @each $cssVar, $value in $cssVarsList {
    #{$cssVar}: $value;
  }
}

@mixin initDefaultTheme($theme) {
  $cssVarsList: createCssVarsList($theme, $cssVarPrefix, $cssVarDefaultPostfix);

  @each $cssVar, $value in $cssVarsList {
    #{$cssVar}: $value;
  }
}

@mixin setCssVar($cssVarPath, $value) {
  #{getCssVarName($cssVarPath...)}: $value;
}
