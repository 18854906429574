@import '../vars';

$ng-select-highlight: #3075f3;
$ng-select-primary-text: #25292e;
$ng-select-disabled-text: #f1f1f6;
$ng-select-border: getColor(gray, 40);
$ng-select-border-radius: 4px;
$ng-select-box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.12);
$ng-select-placeholder: #999999;
$ng-select-value-font-size: 0.9375rem;

@import '../../../../node_modules/@ng-select/ng-select/scss/default.theme.scss';

.ng-select {
  @include lectaFontSize(heading, s);
  @include tablet {
    @include lectaFontSize(text, m);
  }
  .ng-value-container {
    padding-right: 5px;
  }

  &.-size-s {
    @include lectaFontSize(text, m);
    @include tablet {
      @include lectaFontSize(text, m);
    }
  }
}

.ng-select.ng-select-single .ng-select-container {
  min-height: 68px;
  height: 68px;
  border-radius: 8px;
  border: 2px solid getColor(gray, 40);

  @include tablet() {
    min-height: 52px;
    height: 52px;
  }

  @include mobile() {
    min-height: 48px;
    height: 48px;
  }
}

.ng-select.ng-select-single.-size-s .ng-select-container {
  min-height: 54px;
  height: 54px;
  @include tablet() {
    min-height: 50px;
    height: 50px;
  }
}

.ng-select .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-left: 24px;

  @include tablet() {
    padding-left: 18px;
  }
}

.ng-select.-size-s .ng-select-container .ng-value-container,
.ng-select.ng-select-single.-size-s .ng-select-container .ng-value-container .ng-input {
  padding-left: 16px;
}

.ng-select.ng-select-container .ng-value-container .ng-input,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0;
  height: 100%;
  display: flex;
}

.ng-option {
  text-align: initial;
}

ng-dropdown-panel.ng-dropdown-panel {
  box-shadow: 1px 5px 54px rgba(4, 19, 23, 0.19);
  border: none;
  .ng-dropdown-panel-items {
    .ng-option {
      padding: 8px 16px 10px;
      white-space: normal;
      &.ng-option-marked {
        background: #e5f1ff;
      }
    }
  }
}
